import { useEffect, useRef } from "react";
import { Formik, Form, Field, getIn } from "formik";
import * as Yup from "yup";
import { baseUrl } from "../../utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Transition } from "@headlessui/react";
import useBreakpoint from "../../utils/useBreakpoint";

const INITIAL_VALUES = {
  name: "",
  address: {
    location: { lang: "", lat: "" },
    localArea: "",
    taluka: "",
    district: "",
    village: "",
    state: "",
    country: "",
    pincode: "",
  },
  tag: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  tag: Yup.string().required("Tag is required"),
  address: Yup.object().shape({
    // location: Yup.object({
    //   lang: Yup.string()
    //     .required("Longitude is required")
    //     .matches(/^-?\d+(\.\d+)?$/, "Invalid longitude"),
    //   lat: Yup.string()
    //     .required("Latitude is required")
    //     .matches(/^-?\d+(\.\d+)?$/, "Invalid latitude"),
    // }),
    localArea: Yup.string().required("Local Area is required"),
    village: Yup.string().required("Village is required"),
    taluka: Yup.string().required("Taluka is required"),
    district: Yup.string().required("District is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    pincode: Yup.string()
      .required("Pincode is required")
      .matches(/^\d{6}$/, "Invalid pincode"),
  }),
});

function FarmModal({
  userData,
  setModalOpen,
  modalOpen,
  selectedFarm,
  setSelectedFarm,
  setRefetch,
}) {
  const modalContent = useRef(null);
  const { isMobile } = useBreakpoint();
  const currentFarmerId = localStorage.getItem("currentFarmerId");

  const formatSelectedFarm = () => {
    if (selectedFarm) {
      return {
        name: selectedFarm?.name || "",
        tag: selectedFarm?.tag || "",
        address: {
          location: {
            lang: selectedFarm?.address?.location?.lang || "",
            lat: selectedFarm?.address?.location?.lat || "",
          },
          localArea: selectedFarm?.address?.localArea || "",
          taluka: selectedFarm?.address?.taluka || "",
          district: selectedFarm?.address?.district || "",
          village: selectedFarm?.address?.village || "",
          state: selectedFarm?.address?.state || "",
          country: selectedFarm?.address?.country || "",
          pincode: selectedFarm?.address?.pincode || "",
        },
      };
    }
    return INITIAL_VALUES;
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const token = localStorage.getItem("token");
    const finalValues = { ...values, farmerId: currentFarmerId };

    const addMessage = selectedFarm
      ? "Updating farm details..."
      : "Adding farm details...";
    const successMessage = selectedFarm
      ? "Farm details updated successfully 👍"
      : "Farm details added successfully 👍";
    const errorMessage = selectedFarm
      ? "Error updating farm details 🤯"
      : "Error adding farm details 🤯";

    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const endpoint = `${baseUrl}/farm/${selectedFarm?._id || ""}`;
          const method = selectedFarm ? axios.patch : axios.post;
          const response = await method(endpoint, finalValues, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.status === 200) {
            resolve(response);
            setRefetch(true);
            setModalOpen(false);
            setSelectedFarm(null);
            resetForm();
          }
        } catch (error) {
          reject(error);
          console.error(error);
        }
      }),
      {
        pending: addMessage,
        success: successMessage,
        error: errorMessage,
      }
    );
    setSubmitting(false);
  };

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (modalOpen && keyCode === 27) {
        setModalOpen(false);
        setSelectedFarm(null);
      }
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [modalOpen]);

  return (
    <>
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      <Transition
        className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 justify-center px-4"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="bg-white max-w-6xl w-full rounded shadow-lg"
        >
          <button
            type="button"
            className="btn m-5"
            style={{ float: "right", background: "crimson", color: "white" }}
            onClick={() => {
              setModalOpen(false);
              setSelectedFarm(null);
            }}
          >
            X
          </button>
          <main className="p-6">
            <Formik
              initialValues={formatSelectedFarm()}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <Form>
                  <div className="grid gap-6 grid-cols-1 sm:grid-cols-2">
                    {[
                      { name: "name", label: "Name" },
                      { name: "tag", label: "Tag" },
                      { name: "address.location.lang", label: "Longitude" },
                      { name: "address.location.lat", label: "Latitude" },
                      { name: "address.localArea", label: "Local Area" },
                      { name: "address.village", label: "Village" },
                      { name: "address.taluka", label: "Taluka" },
                      { name: "address.district", label: "District" },
                      { name: "address.state", label: "State" },
                      { name: "address.country", label: "Country" },
                      { name: "address.pincode", label: "Pincode" },
                    ].map(({ name, label }) => {
                      const fieldError = getIn(errors, name);
                      const fieldTouched = getIn(touched, name);
                      return (
                        <div key={name}>
                          <label
                            htmlFor={name}
                            className="block text-sm font-medium text-gray-700"
                          >
                            {label}
                          </label>
                          <input
                            type="text"
                            id={name}
                            name={name}
                            value={getIn(values, name)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          {fieldTouched && fieldError && (
                            <p className="text-red-500 text-sm">{fieldError}</p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex justify-end mt-6">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn bg-[#00AB55] text-white"
                    >
                      {selectedFarm ? "Update" : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </main>
        </div>
      </Transition>
    </>
  );
}

export default FarmModal;
